import styled from "styled-components"

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: #4e5964;
`

export const Caption = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: #7e7e7e;
`

export const Paragraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #505050;
  font-weight: 300;
  margin: 0;

  > p {
    margin: 0 0 24px 0;
  }
`
