import styled from "styled-components"

export const Flex = styled.div<{
  flexDirection?: string
  flexGrow?: string
  alignItems?: string
  justifyContent?: string
}>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "row"};
  flex-grow: ${(props) => props.flexGrow || "1"};
  align-items: ${(props) => props.alignItems || "center"};
  justify-content: ${(props) => props.justifyContent || "center"};
`
