import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Flex } from "../../atoms/Flex/Flex"
import { Text } from "../../atoms/Typography/Typography"

const BlackBox = styled.div`
  width: 100vw;
  background: black;
  color: #e4e4e4;
  padding: 10vh 20vw 4vh;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    padding: 2vh 5vw 2vh;
  }
`

const FooterTitle = styled.h5`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;

  display: flex;
  align-items: center;
  color: white;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`

const FooterLogos = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;

  > * {
    margin-right: 16px;
  }

  > *:hover {
    opacity: 0.8;
  }
`

const Credits = styled(Flex)`
  margin-top: 160px;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin-top: 54px;
    font-size: 12px;
  }
`

const FollowText = styled(Text)`
  color: #e4e4e4;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`

interface FooterProps {
  className?: string
}

export const Footer: React.FC<FooterProps> = ({ className }) => (
  <BlackBox className={className}>
    <FooterTitle>Luca Restagno</FooterTitle>
    <FollowText>Follow along</FollowText>
    <FooterLogos>
      <a
        href="https://www.linkedin.com/in/luca-restagno-87641b23/"
        target="_blank"
      >
        <StaticImage
          src="../../../images/linkedin_small_bn.svg"
          alt="linkedin"
          width={23}
          quality={100}
        />
      </a>
      <a href="https://www.twitter.com/ikoichi" target="_blank">
        <StaticImage
          src="../../../images/twitter_small_bn.svg"
          alt="twitter"
          width={23}
          quality={100}
        />
      </a>
    </FooterLogos>
    <Credits>Website built with&nbsp;🍕&nbsp;in Italy&nbsp;🇮🇹</Credits>
  </BlackBox>
)
