import React, { ReactNode } from "react"
import { Helmet } from "react-helmet"
import { createGlobalStyle } from "styled-components"
import splitbee from "@splitbee/web"

splitbee.init()

const GlobalStyle = createGlobalStyle`
  html, body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: 0;
    margin: 0;
  }

  code {
    background: #ececec;
    padding: 0 8px;
    border-radius: 4px;
    font-size: 0.9em;
  }

  a, a:visited {
    color: #4870c4;
  }

  a:hover {
    color: #224288;
  }
`

interface LayoutProps {
  children: ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <React.Fragment>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <title>Luca Restagno</title>
      <link rel="canonical" href="https://www.lucarestagno.com/" />
    </Helmet>
    <GlobalStyle />
    {children}
  </React.Fragment>
)

export default Layout
